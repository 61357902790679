body {
  margin: 0;
  overscroll-behavior: none;
  font-family: Arial, Helvetica, sans-serif;
}

.container {
  background-color: #202124;
  width: auto;
  height: auto;

  padding: 20px;
}

@media (max-width: 600px) {
  .container {
    background-color: white;
    padding: 0;
  }
}

.page {
  margin: auto;
  background-color: white;
  width: 1000px;
  min-height: 1200px;
}

.pageContent {
  padding: 50px;

  font-size: 20px;
  line-height: 2;
}

@media (max-width: 600px) {
  .page {
    margin: auto;
    width: 100vw;
  }

  .pageContent {
    padding: 20px;

    font-size: 16px;
    line-height: 2;
  }
}

.actionButtons {
  position: fixed;
  bottom: 50px;
  left: 50px;
}

.actionButton {
  font-size: 18px;
}

.actionButtonIcon {
  margin-left: 10px;
  margin-top: 1px;
}

@media print {
  .statusBar {
    display: none;
  }
  .actionButtons {
    display: none;
  }
}
